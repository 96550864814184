import { Inject, Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService) { }

  init() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
      });
  }

  login() {
    this.loginWithRedirect();
  }

  logout() {
    this.msalService.logoutRedirect();
  }

  getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  private setLoggedIn() {
    this.loggedIn.next(this.msalService.instance.getAllAccounts().length > 0);
  }

  public checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
    * To use active account set here, subscribe to inProgress$ first in your component
    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    */

    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0]
      }
      this.msalService.acquireTokenSilent(accessTokenRequest).subscribe(response => {
        sessionStorage.setItem("msal.idtoken", response.idToken);
      }, (error: any) => {
        this.login();
      });
    }
  }

  public getIdToken(): Observable<any> {

    let accounts = this.msalService.instance.getAllAccounts();
    this.msalService.instance.setActiveAccount(accounts[0]);
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    }
    return this.msalService.acquireTokenSilent(accessTokenRequest);
  }

  get authenticated(): Observable<boolean> {
    return this.loggedIn;
  }

  private loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }
}
