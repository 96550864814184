import { catchError, OperatorFunction, throwError } from "rxjs";


export class ErrorResponse {
    errors?: IIError[] | undefined;
}

export interface IIError {
    friendlyErrorMessage?: string | undefined;
    errorCode?: string | undefined;
}


export function fetchErrorResponseFromBadRequestPipe<T, A>(): OperatorFunction<T | A, T | A> {
        return catchError((err) => throwError(() => toErrorRespose(err)))
}

export function toErrorRespose(err: any) {
        if (err.status == 400) {
                let response = new ErrorResponse();
                response.errors = err.error.errors;
                return response;
        }

        return err;
}
