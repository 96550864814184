import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { VoucherClient, IVoucherRequest, IVoucherUser } from '../../services/voucher/vouchers-service';
import { ErrorResponse } from 'src/services/voucher/error-response';

@Component({
  selector: 'app-add-voucher-v2',
  templateUrl: './add-voucher-v2.component.html',
  styleUrls: ['./add-voucher-v2.component.css']
})
export class AddVoucherV2Component implements OnInit, OnDestroy {
  model = { email_id: "", voucher_code: "", voucher_amount: null as (null | number), notes: "", start_date: "", expire_date: "" };
  voucherV2Request: IVoucherRequest;
  voucherUser: IVoucherUser;
  public isSubmit: boolean = false;
  endDatepickerConfig!: Partial<BsDatepickerConfig>;
  startDatepickerConfig!: Partial<BsDatepickerConfig>;
  successAlerts: any[] = [];
  failureAlerts: any[] = [];
  private hasChangePermissions: boolean = false;

  constructor(
    private voucherClient: VoucherClient,
    private router: Router) {
    this.voucherV2Request = <IVoucherRequest>{};
    this.voucherUser = <IVoucherUser>{};
    this.configureEndDate();
    this.configureStartDate();
  }

  ngOnInit() {
    this.voucherClient.me().subscribe((data) => {
      this.hasChangePermissions = data.includes("VouchersManager");
      if (this.hasChangePermissions === false)
        this.router.navigate(['/unauthorized']);
    });
  }

  public ngOnDestroy() {
  }

  onSubmit(voucherForm: NgForm) {
    this.isSubmit = true;
    if (this.validateStartDateAndEndDate()) {
      this.setVoucherDetails();
      this.voucherClient.voucherUpload(this.voucherV2Request).subscribe(res => {
        this.isSubmit = false;
        voucherForm.resetForm({ });
        this.showSuccessMsg();
      }
        , err => {
          if (err.status === 409) {
            this.isSubmit = false;
            voucherForm.resetForm({ });
            this.showVoucherExistsMsg();
            console.log(err);
          }
          else {
            this.isSubmit = false;
            voucherForm.resetForm({ });

            if (err instanceof ErrorResponse) {
              const errorResponse = err as ErrorResponse;
              if (errorResponse.errors != null) {
                console.log(errorResponse.errors[0].friendlyErrorMessage);
                this.showVoucherErrorMsg(errorResponse.errors[0].friendlyErrorMessage as string);
              }
            } else {
              this.showErrorMsg();
            }
            console.log(err);
          }
        });

    }
    else {
      this.isSubmit = false;
      this.failureAlerts.push({
        type: 'danger',
        msg: `Voucher start date should not be greater than expiry date!`,
        timeout: 5000
      });
    }
  }


  private showVoucherErrorMsg(errorMessage: string) {
    this.failureAlerts.push({
      type: 'danger',
      msg: errorMessage,
      timeout: 5000
    });
  }


  private showVoucherExistsMsg() {
    this.failureAlerts.push({
      type: 'danger',
      msg: `The voucher entered already exists. Please enter a new voucher code.`,
      timeout: 5000
    });
  }

  private showSuccessMsg() {
    this.successAlerts.push({
      type: 'success',
      msg: `The voucher code has been saved and can be sent to the customer.`,
      timeout: 5000
    });
  }
  private showErrorMsg() {
    this.failureAlerts.push({
      type: 'danger',
      msg: `Something went wrong!`,
      timeout: 5000
    });
  }

  private setVoucherDetails() {
    this.voucherV2Request.voucherCode = this.model.voucher_code,
      this.voucherV2Request.amount = this.model.voucher_amount,
      this.voucherV2Request.startDate = new Date(this.model.start_date),
      this.voucherV2Request.endDate = new Date(this.model.expire_date),
      this.voucherUser.email = this.model.email_id;
    this.voucherUser.notes = this.model.notes;
    this.voucherV2Request.voucherUsers = new Array();
    this.voucherV2Request.voucherUsers.push(this.voucherUser);
  }

  private validateStartDateAndEndDate() {
    let startDate = new Date(this.model.start_date);
    let endDate = new Date(this.model.expire_date);
    if (startDate > endDate) {
      return false;
    }
    return true;
  }

  private configureEndDate() {
    this.endDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-red',
      dateInputFormat: 'DD-MM-YYYY',
      showWeekNumbers: false,
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    });
  }

  private configureStartDate() {
    this.startDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'DD-MM-YYYY',
      showWeekNumbers: false,
      minDate: new Date()
    });
  }
}
