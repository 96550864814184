import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VoucherClient, IVoucherModel } from '../../services/voucher/vouchers-service';

@Component({
  selector: 'app-list-voucher',
  templateUrl: './list-voucher.component.html',
  styleUrls: ['./list-voucher.component.css']
})
export class ListVoucherComponent implements OnInit {
  vouchers : IVoucherModel[];
  vouchersLoaded: boolean = false;

  constructor(
    private voucherClient: VoucherClient,
    private router: Router) {
   }

  ngOnInit() {

   this.getVouchersList();
  }

  private getVouchersList(){

    this.voucherClient.getAllVouchers().subscribe(res => this.vouchers = res.allVouchers )
    .add(() => {
    this.vouchersLoaded = true;
 });    
  }
}
