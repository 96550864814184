import { Component,
  OnDestroy,
  OnInit, } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'Voucher UI';
  isPageNotFound:boolean=false
  private routingEventSubscription: Subscription;
  activatedRoute: any;
  isIframe = false;
  loginDisplay = false;

  constructor(
    private router: Router,
    public authService: AuthService  ) {
    this.authService.init();
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
      this.isIframe = window !== window.parent && !window.opener;
  }


ngOnDestroy(): any {
  this.routingEventSubscription.unsubscribe();
}
}
