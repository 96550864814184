import { ServiceRoutesConfig, ServiceRoutesContainer } from './serviceroute.models'

export const serviceRoutesContainer: ServiceRoutesContainer = {
  hmr: {
    projectWebBaseUrl: 'http://localhost:4200/',
    voucherAPIBaseUrl: 'https://vouchers.villaplustest.com',
    microsoftGraphBaseUrl: 'https://graph.microsoft.com'
  } as ServiceRoutesConfig,
  dev: {
    projectWebBaseUrl: 'https://vouchers-ui.villaplustest.com/',
    voucherAPIBaseUrl: 'https://vouchers.villaplustest.com',
    microsoftGraphBaseUrl: 'https://graph.microsoft.com'
  } as ServiceRoutesConfig,
  production: {
    projectWebBaseUrl: 'https://vouchers-ui.villaplus.com/',
    voucherAPIBaseUrl: 'https://vouchers.villaplus.com',
    microsoftGraphBaseUrl: 'https://graph.microsoft.com'
  } as ServiceRoutesConfig

} as ServiceRoutesContainer;
