<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<h5 class="card-header text-center">Add Marketing Vouchers</h5>
<div *ngFor="let alert of successAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>
<div *ngFor="let alert of failureAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>
<div class="card ">
  <div class="card-body">
    <form name="form" #voucherForm="ngForm" novalidate>
      <div>
        <a href="javascript:" (click)="downloadTemplate()"><mat-icon style="font-size: 20px;">download_sm</mat-icon><span style="vertical-align: top; font-size:small">Download Template</span></a>
      </div>
      <br />
      <div>
        <input #file type="file" accept="text/csv" (change)="onFileChange($event)" />
        <button class="" type="button" (click)="validateFile()" [disabled]="!isFileLoaded">
          Load File
        </button>
        <br /><br />
        <div *ngIf="isValidationResponseLoaded" style="font-weight:bold">File loaded containing {{validationResponse.voucherCount}} records<br /></div>
      </div>
      <div *ngIf="isValidationResponseLoaded">
        <h6 class="card-header text-center">Validation Messages</h6>
        <div class="container">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Message Type</th>
                <th>Voucher Code</th>
                <th>Email Address</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let validationMessage of validationResponse.validationMessages" [ngClass]="{ 'bulk-upload-warning': validationMessage.type.toString() === warningMessageType, 'bulk-upload-error': validationMessage.type.toString() === errorMessageType }">
                <td>{{validationMessage.type}}</td>
                <td>{{validationMessage.code}}</td>
                <td>{{validationMessage.emailAddress}}</td>
                <td>{{validationMessage.message}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <button class="btn btn-primary" type="submit" [disabled]="!isFileValid" (click)="uploadFile()">
          Add Vouchers
        </button>
      </div>
    </form>
  </div>
</div>
