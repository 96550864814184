import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon'
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { environment } from '../environments/environment';
import { VoucherClient, VOUCHER_API_BASE_URL } from '../services/voucher/vouchers-service';
import { AddMarketingVouchersComponent } from './add-markerting-vouchers/add-marketing-vouchers.component';
import { AddVoucherV2Component } from './add-voucher-v2/add-voucher-v2.component';
import { ListVoucherComponent } from './list-voucher/list-voucher.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { VoidVoucherComponent } from './void-voucher/void-voucher.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '../services/msal/msal.factories';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    AddVoucherV2Component,
    AddMarketingVouchersComponent,
    VoidVoucherComponent,
    ListVoucherComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    AlertModule.forRoot(),
    MsalModule,
    BrowserAnimationsModule,
    MatIconModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: VOUCHER_API_BASE_URL,
      useValue: environment.services.voucherAPIBaseUrl
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    VoucherClient,
    MsalGuard,
    MsalService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
