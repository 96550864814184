import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';

import { PageNotFoundComponent } from '../app/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AddVoucherV2Component } from '../app/add-voucher-v2/add-voucher-v2.component';
import { AddMarketingVouchersComponent } from './add-markerting-vouchers/add-marketing-vouchers.component';
import { VoidVoucherComponent } from '../app/void-voucher/void-voucher.component';
import { ListVoucherComponent } from './list-voucher/list-voucher.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/voucher',
    pathMatch: 'full'
  },
  {
    path: 'add-cs-voucher',
    component: AddVoucherV2Component,
    canActivate: [MsalGuard]
  },
  {
    path: 'add-marketing-vouchers',
    component: AddMarketingVouchersComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'void-voucher',
    component: VoidVoucherComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'list-vouchers',
    component: ListVoucherComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    redirectTo: '/page-not-found',
    pathMatch: 'full'
  },
  {
    path: '*path',
    redirectTo: 'page-not-found'
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
