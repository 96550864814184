<h3 class="p-3 text-center">All Vouchers</h3>
<div *ngIf="vouchersLoaded">
  <div class="container">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
                <th>Voucher Code</th>
                <th>Amount</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Email Restriction</th>
              <th>Voided By</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let voucher of vouchers">
                <td>{{voucher.code}}</td>
                <td>{{voucher.amount |currency:"GBP":"symbol"}}</td>
                <td style="width: 11%;">{{voucher.startDate | date:'dd-MM-yyyy'}}</td>
                <td style="width: 11%;">{{voucher.endDate | date:'dd-MM-yyyy'}}</td>
                <td>{{voucher.emailRestriction}}</td>
              <td>{{voucher.voidedBy}}</td>
            </tr>
        </tbody>
    </table>
</div>
</div>
<div *ngIf="!vouchersLoaded">
<img src="../../assets/loading.gif" style="margin-left: 45%;margin-top: 8%;">  
</div>

