import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { VoucherClient } from '../../services/voucher/vouchers-service';
import { ErrorResponse } from 'src/services/voucher/error-response';

@Component({
  selector: 'app-void-voucher',
  templateUrl: './void-voucher.component.html',
  styleUrls: ['./void-voucher.component.css']
})
export class VoidVoucherComponent implements OnInit, OnDestroy {
  model: any = {};
  successAlerts: any[] = [];
  failureAlerts: any[] = [];
  hasChangePermissions: boolean = false;

  constructor(
    private voucherClient: VoucherClient,
    private router: Router) {
  }

  ngOnInit() {

    this.voucherClient.me().subscribe((data) => {
      this.hasChangePermissions = data.includes("VouchersVoidManager");
      if (this.hasChangePermissions === false)
        this.router.navigate(['/unauthorized']);
    });
  }

  public ngOnDestroy() {
  }

  onSubmit(voucherForm: NgForm) {
    this.voucherClient.voidVoucher(this.model.voucher_code)
      .subscribe((data) => {
        this.showSuccessMsg();
      }, (error) => {
        console.log(error);
        if (error instanceof ErrorResponse) {
          const errorResponse = error as ErrorResponse;
          if (errorResponse.errors != null) {
            console.log(errorResponse.errors[0].friendlyErrorMessage);
            this.showVoucherErrorMsg(errorResponse.errors[0].friendlyErrorMessage as string);
          }
        }
        else {
          console.log(error);
          this.showErrorMsg();
        }
      });
  }

  public disableVoidButton(): boolean {
    return !this.hasChangePermissions;
  }

  private showVoucherErrorMsg(errorMessage: string) {
    this.failureAlerts.push({
      type: 'danger',
      msg: errorMessage,
      timeout: 5000
    });
  }

  private showErrorMsg() {
    this.failureAlerts.push({
      type: 'danger',
      msg: `Something went wrong!`,
      timeout: 5000
    });
  }

  private showSuccessMsg() {
    this.successAlerts.push({
      type: 'success',
      msg: `The voucher code is voided.`,
      timeout: 5000
    });
  }
}
